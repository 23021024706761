<template>
    <div>
      <b-container class="mt-5 mb-5">
        <!--
        <b-dropdown dropleft variant="outline-dark" text="Opties" class="float-right">
          <b-dropdown-item href="#">Bezoek ticketshop</b-dropdown-item>
          <b-dropdown-item href="#">Kopieer ticketshop link</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item href="#">Naar toegangsdashboard</b-dropdown-item>
        </b-dropdown>
        -->
        <h1 class="fs-4 font-weight-bold mb-0">Dashboard</h1>
        <p class="text-muted">Welkom bij je nieuwe evenement. Hier onder een aantal belangrijke activiteiten om door te nemen voordat je je evenement publiceert.</p>

        <b-row cols="1" cols-sm="2" cols-md="3">
          <b-col>
            <b-card class="mb-3">
              <div class="text-muted">Verkochte tickets</div>
              <div class="font-weight-bolder fs-4">{{ stats.total_tickets_sold }}</div>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="mb-3">
              <div class="text-muted">Bestellingen</div>
              <div class="font-weight-bolder fs-4">{{ stats.total_orders }}</div>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="mb-3">
              <div class="text-muted">Omzet</div>
              <div class="font-weight-bolder fs-4">{{ stats.total_revenue|toCurrency }}</div>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="mb-3">
              <div class="text-muted">Bezoekers op webshop</div>
              <div class="font-weight-bolder fs-4">{{ stats.total_visitors }}</div>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="mb-3">
              <div class="text-muted">Tickets in reservering</div>
              <div class="font-weight-bolder fs-4">{{ stats.total_tickets_reserved }}</div>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="mb-3">
              <div class="text-muted">Gasten</div>
              <div class="font-weight-bolder fs-4">{{ stats.total_guests }}</div>
            </b-card>
          </b-col>
        </b-row>

        <b-card class="mb-3">
          <template v-if="orders.length">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="text-muted">
                    <th>Bestelnr.</th>
                    <th>Klant</th>
                    <th>Kosten</th>
                    <th>Status</th>
                    <th>Tickets</th>
                    <th>Datum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(order,key) in orders" :key="key" @click="goToDetail(order)" class="cursor-pointer">
                    <td>{{ order.order_id }}</td>
                    <td>{{ order.firstname }} {{ order.lastname }}</td>
                    <td>{{ order.total_price|toCurrency }}</td>
                    <td>
                      <template v-if="order.canceled_at">
                        <b-badge variant="danger" class="bg-danger">
                          Geannuleerd
                        </b-badge>
                      </template>
                      <template v-else>
                        <template v-if="order.payment_status == 'paid'">
                          <b-badge variant="success" class="bg-success">
                            Betaald
                          </b-badge>
                        </template>
                        <template v-if="order.payment_status == 'open' || order.payment_status == 'pending' || order.payment_status == 'created'">
                          <b-badge variant="warning" class="bg-warning">
                            Open
                          </b-badge>
                        </template>
                        <template v-if="order.payment_status == 'expired'">
                          <b-badge variant="danger" class="bg-danger">
                            Verlopen
                          </b-badge>
                        </template>
                        <template v-if="order.payment_status == 'canceled'">
                          <b-badge variant="danger" class="bg-danger">
                            Geannuleerd
                          </b-badge>
                        </template>
                      </template>
                    </td>
                    <td>{{ order.tickets_sold }}</td>
                    <td>{{ order.created_at|formatDateTime }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-else>
            Geen recente bestellingen gevonden.
          </template>
        </b-card>

        <b-button v-if="!event.published_at && (this.isAdmin || this.isManager)" variant="outline-primary" :to="'/manage/events/' + $route.params.id + '/publish'">
          Evenement publiceren
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>

      </b-container>
    </div>
  </template>
  
<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'
  
  export default {
    metaInfo: {
      title: 'Event Dashboard',
    },
    components: {
    },
    data() {
      return {
        event: {},
        stats: {},
        orders: [],
      }
    },
    computed: {},
    methods: {
      ...mapActions(useShopStore, ['setLoading','getShop']),
      getEvent: function() {
        this.setLoading(true);
        this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id)
            .then( response => {
                  this.event = response.data;
                this.setLoading(false);
            }
        ).catch((reason) => {
            console.log(reason);
        });
      },
      getEventStats: function() {
        this.setLoading(true);
        this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id + "/stats")
          .then( response => {
              this.stats = response.data;
              this.setLoading(false);
          }
        ).catch((reason) => {
            console.log(reason);
        });
      },
      getOrders: function() {
        this.setLoading(true);
        this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id + "/orders")
          .then( response => {
              this.orders = response.data;
              this.setLoading(false);
          }
        ).catch((reason) => {
            console.log(reason);
        });
      },
      goToDetail: function(order) {
        this.$router.push('/manage/orders/' + order.id);
      },
      isAdmin() {
        return (localStorage.getItem('role') == 'administrator' ? true : false);
      },
      isManager() {
        return (localStorage.getItem('role') == 'manager' ? true : false);
      }
    },
    created() {
      this.getEvent();
      this.getOrders();
      this.getEventStats();
    },
  }
</script>